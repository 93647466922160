<!-- 包栋设置 -->
<template>
    <section class="cont bundleSetting">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>住宿设置</el-breadcrumb-item>
        <el-breadcrumb-item>包栋设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <el-alert type="warning" :closable="false" show-icon>
        <template slot="title">
          <div>1. 包栋可用于打包售卖某区域或楼层的房间，提升产品多样化，实现渠道组合型产品的直连。</div>
          <div>2. 设置包栋房型以及关联房间，提前录入售卖价格，包栋订单管理更便捷。</div>
        </template>
      </el-alert>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="right-btn">
            <el-button class="bg-gradient" type="primary" @click="addEditEvnet('add')"><span>新增包栋</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" v-loading="loading">
          <el-table-column prop="name" label="包栋名称" min-width="30"></el-table-column>
          <el-table-column prop="shortName" label="简称" min-width="30"></el-table-column>
          <el-table-column label="默认价" align="right" min-width="30">
            <template slot-scope="scope">
              <div v-if="scope.row.defaultPriceType === 'NO'">￥{{ scope.row.defaultPrice}}</div>
              <div v-else>
                <div class="justify"><span>平日</span>  <span>￥{{ scope.row.weekdayPrice}}</span></div>
                <div class="justify"><span>周末</span>  <span>￥{{ scope.row.weekendPrice}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="关联房间" min-width="130">
            <template slot-scope="scope">
              <span v-for="item in JSON.parse(scope.row.associatedRoom)" :key="item.roomId" class="bundleSetting__room"> {{ item.roomTypeName+"/"+item.roomNo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="30">
            <template slot-scope="scope">
              <el-button type="text" @click="addEditEvnet('edit',scope.row)">
                <el-link type="primary">编辑</el-link>
              </el-button>
              <el-button type="text" @click="deleteBt(scope.row)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <el-dialog :title="`${addEdit}包栋`" :visible.sync="show" width="600px" :before-close="handleClose" v-if="show">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="demo-ruleForm" label-position="right" label-width="120px">
        <el-form-item label="包栋名称" prop="roomTypeName">
          <el-input class="width-300"  v-model="ruleForm.roomTypeName" placeholder="如：豪华大床房双家庭包栋" clearable></el-input>
        </el-form-item>
        <el-form-item label="简称" prop="shortName">
          <el-input class="width-300"  v-model="ruleForm.shortName" placeholder="如：双家庭包栋" clearable></el-input>
        </el-form-item>
        <el-form-item label="默认价">
          <el-radio-group v-model="ruleForm.defaultPriceType" @change="changeDefaultPriceType">
            <el-radio label="NO">不区分</el-radio>
            <el-radio label="YES">区分平日、周末</el-radio>
          </el-radio-group>
          <el-form-item class="default" v-if="ruleForm.defaultPriceType === 'NO'" prop="roomTypePrice" key="NO">
            <span style="margin-right: 12px;">默认</span>
            <el-input class="width-220" v-model="ruleForm.roomTypePrice" :onkeyup='dataValidation' placeholder="默认价" clearable>
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
          <div v-else key="YES">
            <el-form-item class="default1" prop="weekdayPrice">
              <span style="margin-right: 12px;">平日</span>
              <el-input class="width-220"  v-model="ruleForm.weekdayPrice" :onkeyup='dataValidation' placeholder="周日至周四" clearable>
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
            <el-form-item class="default" prop="weekendPrice">
              <span style="margin-right: 12px;">周末</span>
              <el-input class="width-220" v-model="ruleForm.weekendPrice" :onkeyup='dataValidation' placeholder="周五、周六" clearable>
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="关联房间" prop="arr">
          <el-cascader class="width-300" v-model="ruleForm.arr" :options="options" :props="props" collapse-tags ref="cascaderAddr" placeholder="请选择关联房间" @change="getChild" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="房费分配" v-if="ruleForm.arr.length">
          <el-form-item class="text-12-tip">
            <div>分配比例只需填写整数即可，分配时按各房间数值占总数比例进行分配</div>
            <el-col :span="12" v-for="(item,index) in checkedNodesArr" :key="item">
              <el-form-item class="room" :prop="`rooms[${index}]`" :rules="rules.rooms">
                <el-tooltip class="item" effect="dark" :content="item" placement="left-start">
                  <span class="text">{{item}}</span>
                </el-tooltip>
                <el-input class="width-100" v-model.number="ruleForm.rooms[index]" :onkeyup='dataValidation' clearable></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">完成</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { pms } from '@/api/interface/pms';
export default {
  data(){
    return {
      hotelId:'',              // 酒店ID
      id:'',                   // 编辑时主键ID
      tableData:[],            // 表格数据
      loading: true,           // 表格加载效果
      props: { multiple: true },
      options: [],             // 关联房间数组
      checkedNodesArr : [],    // 次级选中的节点数组

      ruleForm:{               // 表单数据
        roomTypeName:'',       // 包栋名称
        shortName:'',          // 简称
        defaultPriceType:'NO', // 默认价类型(NO:不区分,YES:区分平日周末)
        roomTypePrice:'',      // 默认价
        weekdayPrice:'',       // 平日价
        weekendPrice:'',       // 周末价
        arr:[],                // 关联房间数组
        rooms:[],              // 房间集合
      },
      rules:{                  //校验规则
        roomTypeName:[{ required:true, message: '请输入包栋名称' , trigger: 'blur'}],
        shortName: [{ required: true, message: '请输入简称', trigger: 'blur' }],
        roomTypePrice:[{ required:true,message: '请输入价格',trigger: 'blur'},],
        weekdayPrice:[{ required:true,message: '请输入价格',trigger: 'blur'},],
        weekendPrice:[{ required:true,message: '请输入价格',trigger: 'blur'},],
        arr:[{ required:true, message: '请选择关联房间' , trigger: 'change'}],
        rooms: [{ required:true, message: '请输入房费分配比例' , trigger: 'blur' }]
      },
      show:false,              // 弹窗显示
      addEdit:'',              // 弹窗标题
      total: 0,                // 用户列表总条目数
      page: 1,                 // 当前页 默认第一页
      limit:10,                // 每页显示数
      prompt: '提示！',
      del_success: "删除成功！",
      add_success: "添加成功！",
      update_success: '修改成功！',
      confirm_remove: '确定要删除吗？',
    }
  },
  computed:{
    ...mapState(['hotelInfo']),
    // 验证输入金额
    dataValidation(){
      return 'value=value.replace(/[^\\d.]/g,"").replace(".", "$#$").replace(/\\./g, "").replace("$#$", ".").replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, "$1$2.$3").replace(/^\\./g, "").replace(/\\.{2,}/g, ".")';
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.hotelId = this.hotelInfo.id;
        this.getData();
        this.getRoomType();
      }
    }
  },
  mounted(){
    this.hotelId = this.hotelInfo.id;
    this.getData();
    this.getRoomType();
  },
  methods:{
    // 获取包栋列表
    getData(){
      const url = pms.getBundleSettingPage + `?limit=${this.limit}&page=${this.page}`;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        roomTypeSource:'PMS',
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.total = res.total;
          this.tableData = res.records;
          this.loading = false;
        }
      })
    },
    // 获取房型列表
    getRoomType(){
      const url = pms.getRoomPrice;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        beginDate:"2022-03-01",                          // 开始日期
        days:7,                                          // 天数
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          let arr = [];
          for (let index = 0; index < res.records.roomTypeList.length; index++) {
            let children = []
            for (let i = 0; i < res.records.roomTypeList[index].rooms.length; i++) {
              children.push({ "value": res.records.roomTypeList[index].rooms[i].id, "label" :res.records.roomTypeList[index].rooms[i].roomNo })
            }
            arr.push({ "value": res.records.roomTypeList[index].id, "label" :res.records.roomTypeList[index].roomTypeName ,"children" :children} )
          }
          this.options = arr;
        }
      })
    },
    // 获取选中的节点数组
     getChild() {
      let childrenId = this.$refs['cascaderAddr'].getCheckedNodes();
      let arr= [];
      for (let index = 0; index < childrenId.length; index++) {
        childrenId[index].parent && arr.push( childrenId[index].parent.label +"/"+  childrenId[index].label);
        if (this.ruleForm.rooms[index] === "" || this.ruleForm.rooms[index] === undefined) {
          this.ruleForm.rooms[index] = 1 ;
        }
      }
      this.checkedNodesArr = arr;
    },
    // 默认价类型变化时触发
    changeDefaultPriceType(){
      this.ruleForm.roomTypePrice = '';
      this.ruleForm.weekdayPrice = '';
      this.ruleForm.weekendPrice = '';
    },
    // 新增房价 / 编辑
    addEditEvnet(state ,item){
      this.show = true;
      if (state === "add") {
        this.addEdit = "新增";
      } else {
        this.addEdit = "编辑";
        this.id = item.id;
        this.ruleForm.roomTypeName = item.name;      // 包栋名称
        this.ruleForm.shortName = item.shortName;    // 简称
        this.ruleForm.defaultPriceType = item.defaultPriceType;    // 默认价类型(NO:不区分,YES:区分平日周末)
        this.ruleForm.roomTypePrice = item.defaultPrice;   // 默认价
        this.ruleForm.weekdayPrice = item.weekdayPrice;    // 平日价
        this.ruleForm.weekendPrice = item.weekendPrice;    // 周末价
        let array = JSON.parse(item.associatedRoom);
        let arr = [];
        let arr2 = [];
        let arr3 = [];
        for (let index = 0; index < array.length; index++) {
          arr.push([array[index].roomTypeId,array[index].roomId]);
          arr2.push(array[index].roomTypeName+"/"+array[index].roomNo);
          arr3.push(array[index].num);
        }
        this.ruleForm.arr = arr;                        // 表格 关联房间数组
        this.checkedNodesArr = arr2;                    // 次级选择 关联房间数组
        this.ruleForm.rooms = arr3;                     // 分配比例
      }
    },
    // 删除
    deleteBt({id}){
      this.$confirm(this.confirm_remove, this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: "warning",
      }).then(() => {
        const url = pms.delBundleSetting+ `${id}`;
        let params = {}
        this.$axios.get(url,params).then((res) => {
          if (res.success) {
            this.$message({showClose: true,message: this.del_success,type: 'success'});
            this.getData();
          }
        })
      });
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getData();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getData();
    },
    // 重置表单数据
    reset(){
      this.ruleForm = {
        roomTypeName:'',       // 包栋名称
        shortName:'',          // 简称
        defaultPriceType:'NO', // 默认价类型(NO:不区分,YES:区分平日周末)
        roomTypePrice:'',      // 默认价
        weekdayPrice:'',       // 平日价
        weekendPrice:'',       // 周末价
        arr:[],                // 关联房间数组
        rooms:[],              // 房间集合
      }
    },
    // 关闭
    handleClose(){
      this.show =false;
      this.reset();
    },
    // 完成
    submitForm(formName){
      console.log(this.ruleForm.rooms);
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.show =false;
          if (this.addEdit === "新增") {
            let arr =[];
            for (let index = 0; index < this.ruleForm.arr.length; index++) {
              arr.push({"roomTypeId":this.ruleForm.arr[index][0],"roomId": this.ruleForm.arr[index][1],"num": this.ruleForm.rooms[index]});
            }
            const url = pms.addbundleSetting;
            const param = {
              hotelId:this.hotelId,                            // 酒店ID
              name:this.ruleForm.roomTypeName,                 // 包栋名称
              shortName:this.ruleForm.shortName,               // 简称
              defaultPriceType:this.ruleForm.defaultPriceType, // 默认价类型(NO:不区分,YES:区分平日周末)
              defaultPrice:this.ruleForm.roomTypePrice,        // 默认价
              weekdayPrice:this.ruleForm.weekdayPrice,         // 平日价
              weekendPrice:this.ruleForm.weekendPrice,         // 周末价
              associatedRoom:JSON.stringify(arr),              // 关联房间 json  [{"roomId":"11"},{"roomId":"22"}]
            }
            this.$axios.post(url, param, 'json').then((res) => {
              if (res.success) {
                this.$message({showClose: true,message: this.add_success,type: 'success'});
                this.reset();
                this.getData();
              }
            })
          } else {
            let arr =[];
            for (let index = 0; index < this.ruleForm.arr.length; index++) {
              arr.push({"roomTypeId":this.ruleForm.arr[index][0],"roomId": this.ruleForm.arr[index][1],"num": this.ruleForm.rooms[index]});
            }
            const url = pms.editBundleSetting;
            const param = {
              id:this.id,                                      // 主键ID
              hotelId:this.hotelId,                            // 酒店ID
              name:this.ruleForm.roomTypeName,                 // 包栋名称
              shortName:this.ruleForm.shortName,               // 简称
              defaultPriceType:this.ruleForm.defaultPriceType, // 默认价类型(NO:不区分,YES:区分平日周末)
              defaultPrice:this.ruleForm.roomTypePrice,        // 默认价
              weekdayPrice:this.ruleForm.weekdayPrice,         // 平日价
              weekendPrice:this.ruleForm.weekendPrice,         // 周末价
              associatedRoom:JSON.stringify(arr),              // 关联房间 json  [{"roomId":"11"},{"roomId":"22"}]
            }
            this.$axios.post(url, param, 'json').then((res) => {
              if (res.success) {
                this.$message({showClose: true,message: this.update_success,type: 'success'});
                this.reset();
                this.getData();
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.bundleSetting{
  .el-alert--warning.is-light{
    padding: 10px;
    border: 1px solid #fa0;
    margin-bottom: 20px;
  }
  .bundleSetting__room{
    border: 1px solid #E9EAED;
    border-radius: 2px;
    padding: 2px 8px;
    margin:0 8px 4px 0;
    display: inline-block;
  }
  .justify{
    display: flex;
    justify-content: space-between;
  }
  .el-form{
    max-height: 514px;
    overflow: auto;
    .el-form-item__content{
      line-height: 40px;
    }
    .default{
      background-color: rgb(245, 247, 250);
      padding: 20px;
      width: 260px;
      /deep/ .el-form-item__error{
        margin-left: 90px;
      }
    }
    .default1{
      background-color: rgb(245, 247, 250);
      padding: 20px 20px 2px 20px;
      width: 260px;
      /deep/ .el-form-item__error{
        margin-left: 90px;
      }
    }
    .text-12-tip{
      background-color: rgb(245, 247, 250);
      padding: 16px;
      width: 268px;
      /deep/ .el-form-item__content{
        line-height: 22px;
        color: #999;
        font-size: 13px;
      }
    }
    .room{
      padding-top: 10px;
      width: 260px;
      .text{
        display: block;
        color: #333;
        font-size: 14px;
        line-height: 32px;
        margin-right: 12px;
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
